import React from 'react';

const Offers = () => {
    return (
        <div className={"max-w-[900px] m-auto px-4 py-12 flex flex-wrap justify-between"}>
            <p className={"text-lg font-bold text-gray-700"}>Booking Insensitive</p>
            <p className={"text-lg font-bold text-gray-700"}> Competitive Rates</p>
            <p className={"text-lg font-bold text-gray-700"}>Book With Flexibility</p>
            <p className={"text-lg font-bold text-gray-700"}>Booking Incentives</p>
            <p className={"text-lg font-bold text-gray-700"}>Trip Insurance</p>

        </div>
    );
};

export default Offers;