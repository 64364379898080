import React, {useState} from 'react';
import {TfiMenu} from "react-icons/tfi";

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
        if (!nav) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "scroll"
        }
    }
    // const changeStyle = () => {
    //     setNav()
    // }

    return (
        <div className={"absolute w-full flex justify-between p-4 items-center"}>
            <h1 className={"text-white font-bold text-2xl z-20"}>Experience</h1>
            <TfiMenu onClick={handleNav} className={"z-20 text-white cursor-pointer"} size={30}/>
            <div
                className={nav ? "fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex-col z-10"
                    : "absolute top-0 h-screen left-[-100%] easy-in duration-500 z-10"}>
                <ul className={"flex flex-col fixed w-full h-full items-center justify-center"}>
                    <li className={"font-bold text-3xl p-8"}>Home</li>
                    <li className={"font-bold text-3xl p-8"}>Destination</li>
                    <li className={"font-bold text-3xl p-8"}>Reservation</li>
                    <li className={"font-bold text-3xl p-8"}>Amenietes</li>
                    <li className={"font-bold text-3xl p-8"}>Home</li>
                    <li className={"font-bold text-3xl p-8"}>Home</li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;